import { useMemo } from "react";

/**
 * Generates and returns a unique ID string.
 */
export function useUniqueId() {
  return useMemo(() => {
    const timestamp = Date.now();
    const random = Math.random() * 999999999999;

    return (timestamp + random).toString(16);
  }, []);
}
